import { Article } from "@shopify/hydrogen/storefront-api-types";
import { Link } from '@remix-run/react';
import { Image } from '@shopify/hydrogen';
import { QUERY_CONSTANTS } from "~/libs/query";
import { getImageLoadingPriority } from "~/libs/const";
import { Button } from "~/components";
import { ArrowTopRightOnSquareIcon } from "@heroicons/react/24/outline";

export function Portfolio({ portfolio } : { portfolio: Article[] }) {
  return (      
    <section className="bg-bg3">
      <div className="fl-container">
        <h2 className="fl-section-header">
          Our latest works
        </h2>
        <div className="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-3">
          {portfolio.map((work, i) => (
            <PortfolioCard
              handle={QUERY_CONSTANTS.PORTFOLIO_HANDLE.toLowerCase()}
              article={work as Article}
              key={work.id}
              loading={getImageLoadingPriority(i, 2)}
            />
          ))}
        </div>
      </div>
    </section>
  )
}


export function PortfolioCard({
  handle,
  article,
  loading,
}: {
  handle: string;
  article: Article;
  loading?: HTMLImageElement['loading'];
}) {
  return (
    <article key={article.id} className="flex flex-col items-start justify-between">
      <Link to={`/${handle}/${article.handle}`}>
        <div className="relative w-full">
        {article.image && (
          <Image
              alt={article.image.altText || article.title}
              className="aspect-[16/9] w-full fl-card-border bg-gray-100 object-cover object-top sm:aspect-[2/1] lg:aspect-[3/2]"
              data={article.image}
              height={400}
              loading={loading}
              sizes="(min-width: 768px) 50vw, 100vw"
              width={600}
              crop="top"
            />
          )}
          <div className="absolute inset-0 ring-1 ring-inset ring-gray-900/10" />
        </div>
        <div className="max-w-xl">
          <div className="mt-8 flex items-center gap-x-1 text-xs">
            {article.tags.map((tag) => 
              <p key={tag} className="bg-contrast text-xs text-primary/80 font-medium inline-flex items-center px-2.5 py-0.5">{tag}</p>
            )}
          </div>
          <div className="group relative">
            <h3 className="mt-3 text-2xl font-semibold group-hover:text-primary/40">
              <span className="absolute inset-0" />
              {article.title}
            </h3>
            <time dateTime={article.publishedAt} className="mt-4 text-sm text-primary/80">
              {article.publishedAt}
            </time>
            <p className="mt-4 line-clamp-3 text-sm text-primary/80">
              {article.excerpt}</p>
          </div>
        </div>
      </Link>
      <div className="relative mt-6 flex items-center gap-x-4">
        <Button to={`/${handle}/${article.handle}`} mode='brutalist'>
          Case study
        </Button>
        { article.metafield?.value &&
          <Button to={article.metafield?.value} target="_blank" mode="brutalist" tone="light">
            <ArrowTopRightOnSquareIcon className="h-5 w-5"/>&nbsp;Live view
          </Button>
        }
      </div>
    </article>
  );
}